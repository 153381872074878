import React from "react";
import "./logo.css";
import logohero from "./assets/logohero.png";
import logo2 from "./assets/logo2.png";
import logo3 from "./assets/logo3.png";
import Hero from "./components/Hero";
import Slider from "./components/Slider";
function Logo() {
  return (
    <div className="Logo">
      <Hero imageSrc={logohero} />
      <Slider imageSrc={logo2} title={"Neden Logo?"} subtitle={""} />
      <Slider
        imageSrc={logo3}
        title={"Kaliteye ulaşmak herkesin hakkı."}
        subtitle={""}
        flipped={true}
      />
    </div>
  );
}

export default Logo;
