import React from "react";
import "./sustable.css";
import sustablehero from "./assets/sustablehero.png";
import sustable1 from "./assets/sustable1.png";
import sustable2 from "./assets/sustable2.png";
import Hero from "./components/Hero";
import Slider from "./components/Slider";
function Sustable() {
  return (
    <div className="Sustable">
      <Hero imageSrc={sustablehero} />
      <Slider
        imageSrc={sustable1}
        title={"Karbon Muhasebesi Nedir?"}
        subtitle={""}
      />
      <Slider
        imageSrc={sustable2}
        title={"Sektöründe öncü ve güvenilir."}
        subtitle={""}
        flipped={true}
      />
    </div>
  );
}

export default Sustable;
