import "./navbar.css";
import React, { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineMenuFold, AiFillPhone, AiOutlineMail } from "react-icons/ai";
import { Link } from "react-router-dom";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  //   const [toggleIcon, setToggleIcon] = useState(<GiHamburgerMenu />);
  useEffect(() => {
    const toggleBtn = document.querySelector(".toggle_btn");
    // const toggleBtnIcon = document.querySelector(".toggle_btn i");
    const dropDownMenu = document.querySelector(".dropdown_menu");

    toggleBtn.onclick = function () {
      dropDownMenu.classList.toggle("open");
      setIsOpen(!isOpen);
      //   setToggleIcon(isOpen ? <GiHamburgerMenu /> : <AiOutlineMenuFold />);
      //   const isOpen = dropDownMenu.classList.contains("open");
      //   toggleBtnIcon.innerHTML = isOpen ? (
      //     <AiOutlineMenuFold />
      //   ) : (
      //     <GiHamburgerMenu />
      //   );
    };
  }, [isOpen]);

  return (
    <div>
      <header>
        <div className="navbar">
          <div className="logo">
            <Link to="/">brnsoft</Link>
          </div>
          <div className="nav_middle">
            <div className="phone">
              <AiFillPhone />
              <div> +90 536 445 9304</div>
            </div>
            <div className="mail">
              <AiOutlineMail />
              <a href="mailto:info@brnsoft.net">info@brnsoft.net</a>
              {/* <div>info@brnsoft.net</div> */}
            </div>
          </div>
          {/* <ul className="links">
            <li>
              <Link to="/logo">Logo</Link>
            </li>
            <li>
              <Link to="/eLogo">eLogo</Link>
            </li>
            <li>
              <Link to="/uniDeva">UniDeva</Link>
            </li>
            <li>
              <Link to="/narBulut">NarBulut</Link>
            </li>
          </ul> */}
          {/* <button
            className="bize_ulasin"
            onClick={() => downloadFile("brnsoftbrosur.pdf")}
          >
            Broşür
          </button> */}
          <a href="brnsoftbrosur.pdf" download="brnsoftbrosur">
            <button className="bize_ulasin">Broşür</button>
          </a>
          <div className="toggle_btn">
            <i className="toggle_icon">
              {isOpen ? <AiOutlineMenuFold /> : <GiHamburgerMenu />}
            </i>
          </div>
        </div>
        <div className="dropdown_menu">
          <li>
            <Link to="/logo">Logo</Link>
          </li>
          <li>
            <Link to="/elogo">eLogo</Link>
          </li>
          {/* <li>
            <Link to="/uniDeva">UniDeva</Link>
          </li> */}
          <li>
            <Link to="/mihsap">Mihsap</Link>
          </li>
          <li>
            <Link to="/narBulut">NarBulut</Link>
          </li>
          <li>
            <Link to="/bilimp">Bilimp</Link>
          </li>
          <li>
            <a href="brnsoftbrosur.pdf" download="brnsoftbrosur">
              <button className="bize_ulasin">Broşür</button>
            </a>
          </li>
        </div>
      </header>
      {/* <main>
        <section id="logo">
          <h1>Welcome</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit.
            <br /> Suscipit ratione, beatae ad eos perspiciatis.
          </p>
        </section>
      </main> */}
    </div>
  );
}
