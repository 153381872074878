import React from "react";

export default function Product(props) {
  return (
    <div>
      <div className="card">
        <img className="product--image" src={props.url} alt="Ürün Resimleri" />
        <div className="card-body">
          <h2 className="card-title">{props.name}</h2>
          <p className="card-info">{props.desc}</p>
        </div>
      </div>
    </div>
  );
}
