import React from "react";
import "./Bilnex.css";
import bilnexhero from "./assets/bilnexhero.png";
import bilnex1 from "./assets/bilnex1.png";
import bilnex2 from "./assets/bilnex2.png";
import Hero from "./components/Hero";
import Slider from "./components/Slider";
function Bilnex() {
  return (
    <div className="Bilnex">
      <Hero imageSrc={bilnexhero} />
      <Slider imageSrc={bilnex1} title={"Neden Bilnex?"} subtitle={""} />
      <Slider
        imageSrc={bilnex2}
        title={"Bilnex size neler sunar?"}
        subtitle={""}
        flipped={true}
      />
    </div>
  );
}

export default Bilnex;
