import React from "react";
import "./mihsap.css";
import logohero from "./assets/mihsaphero.png";
import logo2 from "./assets/mihsap2.png";
import logo3 from "./assets/mihsap3.png";
import Hero from "./components/Hero";
import Slider from "./components/Slider";
function Mihsap() {
  return (
    <div className="Bilimp">
      <Hero imageSrc={logohero} />
      <Slider imageSrc={logo2} title={"Neden Mihsap?"} subtitle={""} />
      <Slider
        imageSrc={logo3}
        title={"Muhasebenin Geleceği"}
        subtitle={""}
        flipped={true}
      />
    </div>
  );
}

export default Mihsap;
