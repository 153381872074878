import React from "react";
import "./style.css";
import { Link } from "react-router-dom";
import Scripts from "./Scripts";

export default function TopSlider() {
  // const SliderTitle = styled.h1`
  //   text-align: center;
  // `;

  return (
    <div className="container">
      {/* sol resim */}
      <div className="kaymacontainer">
        <img
          className="sol_resim"
          src="https://brnsoft.net/pictures/mainslider/lefthand.png"
          alt=""
        />
      </div>
      <div className="topbody">
        <div className="accordion">
          <div className="accordion-content">
            <header>
              <img src="https://brnsoft.net/pictures/icons/logo.png" alt="" />
              {/* <span className="title">brnsoft</span> */}
              {/* <BsInfoCircle className="icon" /> */}
            </header>
            <p className="description">
              <div className="detay">
                "İşinizin her noktasında rekabet avantajı kazanın."
              </div>
              Farklı ihtiyaçlara en uygun ürünlerle sektöründe deneyimli ve öncü
              marka. Erp-Kurumsal kaynak, insan kaynakları yönetimi, Perakende
              satış yönetimi, e-çözümler ve daha fazlası...
              <div className="detay">
                <Link to="/logo">Detay bilgi için tıklayınız...</Link>
              </div>
            </p>
          </div>
          <div className="accordion-content">
            <header>
              <img src="https://brnsoft.net/pictures/icons/elogo.png" alt="" />
            </header>
            <p className="description">
              <div className="detay">"e-Dönüşümde Sorunsuz Çözüm"</div>
              Türkiye’nin e’si eLogo’nun tüm yazılımlarla entegre olabilen
              altyapısı, uzman kadrosu ve müşteri odaklı hizmet anlayışı ile
              dijital dönüşüm süreçlerinizi kolaylaştırın.
              <div className="detay">
                <Link to="/elogo">Detay bilgi için tıklayınız...</Link>
              </div>
            </p>
          </div>
          <div className="accordion-content">
            <header>
              <img src="https://brnsoft.net/pictures/icons/bilnex.png" alt="" />
            </header>
            <p className="description">
              <div className="detay">"Finans yönetimine yeni boyut"</div>
              Ön Muhasebe, Genel muhasebe ve sektörel alanlarda her türlü
              muhasebenizi kolayca tutabileceğiniz yeni nesil sistem.
              <div className="detay">
                <Link to="/bilnex">Detay bilgi için tıklayınız...</Link>
              </div>
            </p>
          </div>
          {/* <div className="accordion-content">
            <header>
              <img
                src="https://brnsoft.net/pictures/icons/unideva.png"
                alt=""
              />
            </header>
            <p className="description">
              Unideva muhasebe yazılımları, internetin olduğu her yerden
              kullanılabilen, verilerinizin bulut sunucularda güvenle muhafaza
              edildiği ön muhasebe ve resmi muhasebe bilgilerinizin güncel
              mevzuata uygun şekilde kayıt altına alınabildiği internet tabanlı
              muhasebe programlarıdır.
              <div>
                <Link to="/unideva">Detay Bilgi</Link>
              </div>
            </p>
          </div> */}
          <div className="accordion-content">
            <header>
              <img src="https://brnsoft.net/pictures/icons/mihsap.png" alt="" />
            </header>
            <p className="description">
              <div className="detay">"Muhasebenin Geleceği"</div>
              Muhasebe Evrak Kayıt Sürecini Dijitalleştirip, defter kaydına
              kadar olan süreci otomatik gerçekleştirip tek tıkla sizin için
              paket programınıza aktarsın ve arşivlesin.
              <div className="detay">
                <Link to="/mihsap">Detay bilgi için tıklayınız...</Link>
              </div>
            </p>
          </div>
          <div className="accordion-content">
            <header>
              <img
                src="https://brnsoft.net/pictures/icons/narbulut.png"
                alt=""
              />
            </header>
            <p className="description">
              <div className="detay">"Narbulut'la her şey çok kolay!"</div>
              Narbulut ile Verilerinizi Güvende Ve Gizli Tutun. Yedekle,
              oluştur, düzenle, bul, yetki ver ve paylaş. Herkes aynı dosyaya
              ulaşsın, istedikleri yerden erişip düzenleyebilsinler.
              {/* <br /> */}
              <div className="detay">
                <Link to="/narbulut">Detay bilgi için tıklayınız...</Link>
              </div>
            </p>
          </div>
          <div className="accordion-content">
            <header>
              <img src="https://brnsoft.net/pictures/icons/Bilimp.png" alt="" />
            </header>
            <p className="description">
              <div className="detay">
                "Bilin, Planlayın ve İnsan kaynaklarınızı verimli yönetin."
              </div>
              Bilimp iş araçları ile Şirket veya Kurumunuzun E-Dönüşümünü
              başarmak için çok sayıda yazılım kullanmaya ihtiyaç duymazsınız.
              "Bütünleşik Araçlar Teknolojisi" ile maliyetlerinizi hızlı ve
              kolayca azaltırken performansınızıda artırırsınız.
              <div className="detay">
                <Link to="/bilimp">Detay bilgi için tıklayınız...</Link>
              </div>
            </p>
          </div>
          <div className="accordion-content">
            <header>
              <img
                src="https://brnsoft.net/pictures/icons/sustable.png"
                alt=""
              />
            </header>
            <p className="description">
              <div className="detay">"Yeni Karbon Muhasebesi"</div>
              Şirketinizin kolayca Karbon Ayak İzini Hesaplayın, Raporlayın,
              Dekarbonizasyonunu başlatın. Zamanında ve Güvenle Takip edin.
              <div className="detay">
                <Link to="/sustable">Detay Bilgi için tıklayınız...</Link>
              </div>
            </p>
          </div>

          {/* <div className="accordion-content">
            <header>
              <img
                src="https://brnsoft.net/pictures/icons/brnsoft.png"
                alt=""
              />
            </header>
            <p className="description">
              Crm, teklif ve ihtiyaçlarınıza özel yazılımlarımızla yanınızdayız.
              <div>
                <Link to="/brnsoft">Detay Bilgi</Link>
              </div>
            </p>
          </div> */}

          <Scripts></Scripts>
        </div>
      </div>
      {/* sağ resim */}
      <div className="kaymacontainer">
        <img
          className="sag_resim"
          src="https://brnsoft.net/pictures/mainslider/righthand.png"
          alt=""
        />
      </div>
    </div>
  );
}
