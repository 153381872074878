import "./home.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Product from "../Firma/Product";
import {
  bilimpProductData,
  eLogoProductData,
  logoProductData,
  mihsapProductData,
  narbulutProductData,
  responsive,
  sustableProductData,
  bilnexProductData,
  // unidevaProductData,
} from "../../data"; //önemli, responsive tek yerden alabilir, data farklı olacak firma ismine göre
import Topslider from "./TopSlider";

function Home() {
  //firmaları alttaki gibi oluşturacağız, product yerine firma ismi, data.js yerine firma.js o kadar
  // const unidevaProduct = unidevaProductData.map((item) => (
  //   <Product
  //     id={item.id}
  //     name={item.name}
  //     url={item.imageurl}
  //     desc={item.desc}
  //   />
  // ));
  const eLogoProduct = eLogoProductData.map((item) => (
    <Product
      id={item.id}
      name={item.name}
      url={item.imageurl}
      desc={item.desc}
    />
  ));
  const bilnexProduct = bilnexProductData.map((item) => (
    <Product
      id={item.id}
      name={item.name}
      url={item.imageurl}
      desc={item.desc}
    />
  ));
  const logoProduct = logoProductData.map((item) => (
    <Product
      id={item.id}
      name={item.name}
      url={item.imageurl}
      desc={item.desc}
    />
  ));

  const mihsapProduct = mihsapProductData.map((item) => (
    <Product
      id={item.id}
      name={item.name}
      url={item.imageurl}
      desc={item.desc}
    />
  ));

  const narbulutProduct = narbulutProductData.map((item) => (
    <Product
      id={item.id}
      name={item.name}
      url={item.imageurl}
      desc={item.desc}
    />
  ));
  const bilimpProduct = bilimpProductData.map((item) => (
    <Product
      id={item.id}
      name={item.name}
      url={item.imageurl}
      desc={item.desc}
    />
  ));

  const sustableProduct = sustableProductData.map((item) => (
    <Product
      id={item.id}
      name={item.name}
      url={item.imageurl}
      desc={item.desc}
    />
  ));

  return (
    <div>
      <Topslider />
      <div
        style={{ textAlign: "center", fontWeight: "bold", fontSize: "35px" }}
      >
        Güvenilir Tercihler
      </div>
      <div className="Home">
        {/* <h1>brnsoft</h1> */}
        {/* carosellerin başına bi başlık */}
        <div className="card-list-detail">
          {/* carosellerin başına bi başlık */}
          <h2 className="firmabaslik">Logo - Erp</h2>

          <Carousel
            // removeArrowOnDeviceType={["tablet", "mobile"]}
            responsive={responsive}
          >
            {logoProduct}
          </Carousel>
          <h2 className="firmabaslik">Elogo - E-Dönüşüm</h2>
          <Carousel
            // removeArrowOnDeviceType={["tablet", "mobile"]}
            responsive={responsive}
          >
            {eLogoProduct}
          </Carousel>
          <h2 className="firmabaslik">Bilnex - Erp</h2>
          <Carousel
            // removeArrowOnDeviceType={["tablet", "mobile"]}
            responsive={responsive}
          >
            {bilnexProduct}
          </Carousel>
          <h2 className="firmabaslik">Mihsap - Belge Aktarım ve Yönetimi</h2>
          <Carousel
            // removeArrowOnDeviceType={["tablet", "mobile"]}
            responsive={responsive}
          >
            {mihsapProduct}
          </Carousel>
          {/* <h2 className="firmabaslik">Unideva</h2>
          <Carousel
            // removeArrowOnDeviceType={["tablet", "mobile"]}
            responsive={responsive}
          >
            {unidevaProduct}
          </Carousel> */}
          <h2 className="firmabaslik">Narbulut - Bulut Yedekleme</h2>
          <Carousel
            // removeArrowOnDeviceType={["tablet", "mobile"]}
            responsive={responsive}
          >
            {narbulutProduct}
          </Carousel>
          <h2 className="firmabaslik">
            Bilimp - Crm, Ebys, Belge ve Proje Yönetimi
          </h2>
          <Carousel
            // removeArrowOnDeviceType={["tablet", "mobile"]}
            responsive={responsive}
          >
            {bilimpProduct}
          </Carousel>
          <h2 className="firmabaslik">Sustable - Karbon Muhasebesi</h2>
          <Carousel
            // removeArrowOnDeviceType={["tablet", "mobile"]}
            responsive={responsive}
          >
            {sustableProduct}
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default Home;

//carousel
//infinite={true} - devamlı sağa sola akıyor
