export const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1024 },
    items: 4,
    slidesToSlide: 1, //oklara basınca kaçar kaysın diye
  },
  desktop: {
    breakpoint: { max: 1024, min: 800 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 800, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
//https://www.npmjs.com/package/react-multi-carousel

//alttaki const dediğimizn yanına firmaları oluşturucaz datalar burda tutulacak
export const unidevaProductData = [
  {
    id: 1,
    imageurl: "https://brnsoft.net/pictures/unideva/prime.png",
    name: "Unideva Prime",
    desc: "Mali Müşavirler için. Genel Muhasebe, İşletme defteri (Defter Beyan), Bordro, e-Defter, Beyannameler, Sabit Kıymet, Mükellef Entegrasyonu, e-Belgeler Entegrasyonu",
  },
  {
    id: 2,
    imageurl: "https://brnsoft.net/pictures/unideva/eliteprime.png",
    name: "Unideva Elite Prime",
    desc: "Tüm Finans İşlemleriniz bir arada. Elite modüller(Ön Muhasebe) + Prime Modüller(Genel Muhasebe) + Edönüşüm Modülleri",
  },
  {
    id: 3,
    imageurl: "https://brnsoft.net/pictures/unideva/suiteprime.png",
    name: "Unideva Suite Prime",
    desc: "Üretim dahil Tüm Finans İşlemleriniz bir arada. Elite modüller(Ön Muhasebe) + Suite Modüller(Üretim) + Prime Modüller(Genel Muhasebe) + Edönüşüm Modülleri",
  },
  {
    id: 4,
    imageurl: "https://brnsoft.net/pictures/unideva/lite.png",
    name: "Unideva Lite",
    desc: "Serbest meslek ve küçük işletmeler için. Cari, Kasa, Gelir/Gider, Fatura, Ajanda, Şirket içi mesajlaşma, İş Listesi, Dosya Yönetimi, İletişim modülleri mevcuttur.",
  },
  {
    id: 5,
    imageurl: "https://brnsoft.net/pictures/unideva/elites.png",
    name: "Unideva Elite S",
    desc: "Kobiler için. Stok, Cari, Kasa, Gelir/Gider, Fatura, irsaliye , Ajanda, Şirket içi mesajlaşma, İş Listesi, Dosya Yönetimi, İletişim modülleri mevcuttur.",
  },

  {
    id: 6,
    imageurl: "https://brnsoft.net/pictures/unideva/elitem.png",
    name: "Unideva Elite M",
    desc: "Stok, Cari, Kasa, Gelir/Gider, Fatura, irsaliye, Çek/Senet, Sipariş, Teklif, Barkod, Ajanda, Şirket içi mesajlaşma, İş Listesi, Dosya Yönetimi, İletişim modülleri mevcuttur.",
  },
  {
    id: 7,
    imageurl: "https://brnsoft.net/pictures/unideva/elitel.png",
    name: "Unideva Elite L",
    desc: "Stok, Cari, Kasa, Gelir/Gider, Fatura, irsaliye, Çek/Senet, Sipariş, Teklif, Barkod, Ödeme/Tahsilat, Kredi Kartı(Pos) Tahsilat, İhracat/İthalat, Proje/Takip, Ajanda, Şirket içi mesajlaşma, İş Listesi, Dosya Yönetimi, İletişim modülleri mevcuttur.",
  },
];
export const eLogoProductData = [
  {
    id: 21,
    imageurl: "https://brnsoft.net/pictures/elogo/efatura.png",
    name: "e-Fatura",
    desc: "Faturalar elektronik ortama taşındığı için kağıt, baskı, posta ve arşivleme maliyetleri ortadan kalkar. Faturalama ve muhasebe süreçlerinde hız artar, zamandan tasarruf sağlanır ve ticareti hızlanır.",
  },
  {
    id: 22,
    imageurl: "https://brnsoft.net/pictures/elogo/earsivfatura.png",
    name: "e-Arşiv Fatura",
    desc: "Faturalar güvenilir bir şekilde elektronik ortamda saklanır, odalar dolusu klasörlenmiş arşivler ve saklama masrafları tarih olur.",
  },
  {
    id: 23,
    imageurl: "https://brnsoft.net/pictures/elogo/eirsaliye.png",
    name: "e-İrsaliye",
    desc: "e-İrsaliye, yeni bir belge türü olmayıp, kağıt ortamdaki “sevk irsaliyesi” belgesi ile aynı hukuki niteliklere sahip dijital irsaliye belgesidir. Elektronik ortamdaki anlık ve otomatik veri kaydı ile hata oranı azalır.",
  },
  {
    id: 24,
    imageurl: "https://brnsoft.net/pictures/elogo/edefter.png",
    name: "e-Defter",
    desc: "e-Defter uygulamasıyla birlikte dosyalarınızı güvenli bir ortamda saklamış olursunuz. Sayfa adedine göre kağıt bastırmanız ve de notere onaylatmanız gerekmez.",
  },
  {
    id: 25,
    imageurl: "https://brnsoft.net/pictures/elogo/gibearsivfatura.png",
    name: "GİB e-Arşiv Fatura",
    desc: "Kolay Kullanım, Lsteleyin ve Toplu indirin, Otomatik e-Posta/ SMS Hizmeti, Mobil Uygulama",
  },
  {
    id: 26,
    imageurl: "https://brnsoft.net/pictures/elogo/esmm.png",
    name: "e-Smm",
    desc: "Düşük Maliyet, Güvenli Kayıt, Hızlı ve Verimli, Kolay Entegrasyon, Çevre Dostu, Yasal Mevzuata Uyum",
  },
  {
    id: 27,
    imageurl: "https://brnsoft.net/pictures/elogo/emm.png",
    name: "e-Müstahsil Makbuzu",
    desc: "e-Müstahsil Makbuzu ile müstahsil makbuzlarınızı elektronik ortamda oluşturarak operasyonlarınızı kolaylaştırabilir, maliyetlerinizi azaltıp, geçmiş müstahsil makbuzlarınıza dilediğiniz yerden anında erişim sağlayabilirsiniz.",
  },
  {
    id: 28,
    imageurl: "https://brnsoft.net/pictures/elogo/eadisyon.png",
    name: "e-Adisyon",
    desc: "Adisyon kağıdını temin etmenize gerek kalmadan, sistemin sağladığı adisyon belgesi üzerinden dijital ortamda işlemleri tamamlar. Sürecin elektronik ortamda takibinin kolaylaşmasını ve raporlanabilmesini sağlar",
  },
  {
    id: 29,
    imageurl: "https://brnsoft.net/pictures/elogo/emutabakat.png",
    name: "e-Mutabakat",
    desc: "BA-BS'lerinizi eLogo e-Mutabakat sistemi üzerinden yapabilirsiniz. Ayrıca mutabık kalınmadığı durumlarda, karşılıklı gönderilen ekstre hesaplarınızı sistem üzerinde karşılaştırılarak farkları tespit edebilirsiniz.",
  },
];
export const logoProductData = [
  {
    id: 1,
    imageurl: "https://brnsoft.net/pictures/logo/start3.png",
    name: "Logo Start 3",
    desc: "Satış, Stok Maliyet gibi kritik bütün işlemlerinizi yapabilirsiniz. Tek kullanıcılı, küçük ölçekli işletmelere ve şahıs firmalarına yönelik bir çözüm olan START, günlük işlemlerin daha etkin ve verimli bir şekilde izlenip yönetilmesini sağlar.",
  },
  {
    id: 2,
    imageurl: "https://brnsoft.net/pictures/logo/go3.png",
    name: "Logo Go 3",
    desc: "KOBİ'lere özel olarak geliştirilen Logo GO 3 ile stoktan siparişe, muhasebe kayıtlarından banka verilerine kadar tüm iş süreçleri tek noktadan etkin bir şekilde yönetiliyor.",
  },

  {
    id: 3,
    imageurl: "https://brnsoft.net/pictures/logo/tiger3.png",
    name: "Logo Tiger 3",
    desc: "Logo Tiger 3 ERP sistemini işletmenizde yerleşik olarak devreye alın, veri yönetiminden satış operasyonlarına, üretim ve tedarik de dâhil olmak üzere  tüm süreçlerinizi merkezî olarak yönetin.",
  },
  {
    id: 4,
    imageurl: "https://brnsoft.net/pictures/logo/gowings.png",
    name: "Logo Go Wings",
    desc: "Web üzerinden kullanılabilen Logo GO Wings ile, KOBİ’lerin siparişten muhasebeye kadar uzanan iş süreçleri her an her yerden anlık olarak yönetiliyor.",
  },
  {
    id: 5,
    imageurl: "https://brnsoft.net/pictures/logo/tigerwings.png",
    name: "Tiger Wings",
    desc: "Web üzerinden kullanılabilen Logo Tiger Wings ERP, geniş modüler yapısı ve zengin fonksiyonlarıyla orta ve büyük ölçekli işletmelerin iş süreçlerini iyileştirerek verimliliği arttırıyor.",
  },
  {
    id: 6,
    imageurl: "https://brnsoft.net/pictures/logo/malimusavir3.png",
    name: "Mali Müşavir 3",
    desc: "Serbest Muhasebeci Mali Müşavirler için özel olarak tasarlanan, beyannamelerden bordrolara, defter beyan süreçlerinden büro yönetimine kadar çok sayıda modülle de iş yükünü azaltıp zaman tasarrufu kazandırıyor.",
  },
  {
    id: 7,
    imageurl: "https://brnsoft.net/pictures/logo/isbasi.png",
    name: "Logo İşbaşı",
    desc: "Ön muhasebe işlemlerine her an, her yerden kolayca ulaşmayı sağlayan online ön muhasebe programı Logo İşbaşı ile mikro ölçekli şirketler işlerini sürekli kontrol altında tutabiliyor.",
  },
  {
    id: 8,
    imageurl: "https://brnsoft.net/pictures/logo/jplatform.png",
    name: "j-Platform",
    desc: "Orta ve büyük ölçekli işletmelerin tüm iş süreçlerinin uçtan uca, verimli ve etkin yönetilmesini sağlayan Logo j-Platform ERP, her an, her yerden erişilebilirken HTML5 web teknolojisiyle kullanıcı deneyimini en üst seviyeye taşıyor.",
  },
  {
    id: 9,
    imageurl: "https://brnsoft.net/pictures/logo/jhr.png",
    name: "j-HR",
    desc: "Web tabanlı yapısı sayesinde İK süreçlerine her an, her yerden erişmeyi sağlayan yeni nesil İK yönetimi, zamandan ve mekandan bağımsız çalışma imkanı sunarak İK ekiplerine ve yöneticilerine esneklik ve hız kazandırıyor.",
  },
  {
    id: 10,
    imageurl: "https://brnsoft.net/pictures/logo/bordroplus.png",
    name: "BORDRO PLUS",
    desc: "İK departmanlarının bordro işlemlerini hızlı ve hatasız gerçekleştirmelerine olanak tanıyan Logo Bordro Plus çözümü, işlem takibinin kolaylığı sayesinde operasyonel maliyetleri de düşürüyor.",
  },
];
export const narbulutProductData = [
  {
    id: 1,
    imageurl: "https://brnsoft.net/pictures/narbulut/backupstd.png",
    name: "Backup Now Std \n 20GB'tan Başlayan Paketler",
    desc: "Dosya Yedekleme, E-Posta Yedekleme, Geri Dönüşüm Kutusu, Klasör Geri Yükleme, 2FA Kimlik Doğrulaması, Limitsiz Trafik, 7/24 E-Posta Destek",
  },
  {
    id: 2,
    imageurl: "https://brnsoft.net/pictures/narbulut/backuppro.png",
    name: "Backup Now Pro\n 100GB'tan Başlayan Paketler",
    desc: "Veritabanı Yedekleme, Dosya Yedekleme, E-Posta Yedekleme, Geri Dönüşüm Kutusu, Klasör Geri Yükleme, 2FA Kimlik Doğrulaması, Limitsiz Trafik, 7/24 E-Posta Destek",
  },
  {
    id: 3,
    imageurl: "https://brnsoft.net/pictures/narbulut/workspace.png",
    name: "Ndocs Workspace \n 100GB'tan Başlayan Paketler",
    desc: "Narbulut nDocs Workspace ile personellerinizle ortak alanda, online ofis şeklinde çalışın.",
  },
  {
    id: 4,
    imageurl: "https://brnsoft.net/pictures/narbulut/user.png",
    name: "Ndocs User \n 100GB'tan Başlayan Paketler",
    desc: "Narbulut nDocs Workspace ile size özel kişisel alanda çalış, yetki ver paylaş.",
  },
];
export const bilimpProductData = [
  {
    id: 1,
    imageurl: "https://brnsoft.net/pictures/bilimp/1gorev.png",
    name: "Akıllı Görev \n ",
    desc: "Görev oluşturma, Görev İletişimi, Görev takibi ve raporlama. Kesintisiz ve izlenebilir iş akışı sağlayın",
  },
  {
    id: 2,
    imageurl: "https://brnsoft.net/pictures/bilimp/2calisma.png",
    name: "Gün içinde yapılan işleri daha kolay izleyin ",
    desc: "Geriye dönük çalışma bilgisi kayıtlarından herhangi bir tarihte veya bir firma ile ilgili yaptığınız çalışmanın ayrıntılarına kolayca ulaşın.",
  },
  {
    id: 3,
    imageurl: "https://brnsoft.net/pictures/bilimp/3randevu.png",
    name: "Toplantılarınızı oluşturun, toplantı salonlarınızı yönetin ",
    desc: "Randevu veya toplantı oluşturma, salon yönetimi, çevrimiçi toplantılar, ikram yönetimi.",
  },
  {
    id: 4,
    imageurl: "https://brnsoft.net/pictures/bilimp/4biri.png",
    name: "Biri - Akıllı bildirim merkezi",
    desc: "Biri ile 27 farklı kategoride hem mobil hem web üzerinden bildirim alın, anlık bildirimlerle bilmenin farkını yaşayın.",
  },
  {
    id: 5,
    imageurl: "https://brnsoft.net/pictures/bilimp/5bimesaj.png",
    name: "Bimesaj ile kurum içi mesajlarınızı güvenli hale getirin",
    desc: "Anlık mesajlaşma ile çevrimiçi kalın, tüm ekibinize her zaman ulaşın.",
  },
  {
    id: 6,
    imageurl: "https://brnsoft.net/pictures/bilimp/6surec.png",
    name: "Sürükle bırak tekniğiyle kolayca süreç akışını oluşturun",
    desc: "Bilimp'in süreç motoru ile kendi işletmenize özel süreçler tasarlayın, kişiselleştirin.",
  },
  {
    id: 7,
    imageurl: "https://brnsoft.net/pictures/bilimp/7ofis.png",
    name: "Ofis Yönetim Araçları",
    desc: "Paylaşım, tablo, yazı, ortak dokümanları, e-postalarınızı, telefon çağrılarını, kişi ve firma bilgilerini, kargo ve gönderilerinizi yönetin.",
  },
  {
    id: 8,
    imageurl: "https://brnsoft.net/pictures/bilimp/8proje.png",
    name: "Projelerinizi Kolayca Yönetin ve İzlenmesini Sağlayın",
    desc: "Proje Başlatma ve Yatırım Talebi Oluşturma. Projeleri planlama, yürütme, izleme ve değerlendirme. Program Yönetimi ile projeleri birlikte yürütme.",
  },
  {
    id: 9,
    imageurl: "https://brnsoft.net/pictures/bilimp/9kvkk.png",
    name: "Kişisel veri yönetim otomasyonu",
    desc: "Bilimp KVKK Aracı ; Kurum ve şirketlerde KVKK uyumluluğu için yapılması zorunlu Hukuki ve Teknik süreçleri kolaylaştıran ve sürdürülebilir bir yapı sağlamak için geliştirilen en kapsamlı yazılımdır.",
  },
  {
    id: 10,
    imageurl: "https://brnsoft.net/pictures/bilimp/10satis.png",
    name: "Satış departmanını izlemek ve yönetmek artık çok kolay",
    desc: "Karmaşık raporlara gerek kalmadan hedeflerinizi ve yaptığınız satışları satış panelinden izleyin. Tekliflerinizi ve sözleşmelerinizi kolayca hazırlayın.",
  },
  {
    id: 11,
    imageurl: "https://brnsoft.net/pictures/bilimp/11oneri.png",
    name: "Öneri paneli ile Ekip ruhunu güçlendirin",
    desc: "Tüm çalışanlarınızın fikirlerini değerlendirebilmeniz, katılımcı bir çalışma ortamı yaratabilmeniz ve operasyonel verimliliği arttırıp işletmenizi bir üst seviyeye çıkartmanız için bir çözümümüz var!",
  },
  {
    id: 12,
    imageurl: "https://brnsoft.net/pictures/bilimp/12ik.png",
    name: "İnsan Kaynaklarını Yönetmenin Kolay Yolunu Keşfedin",
    desc: "Personel Özlükleri, İş-Staj Başvuruları ve İzin Giriş ve Tahakkuk yönetimini pratikleştiren iş aracı.",
  },
  {
    id: 13,
    imageurl: "https://brnsoft.net/pictures/bilimp/13kalite.png",
    name: "Sürdürülebilir kalite",
    desc: "Kurumunuzdaki kalite yönetim sistemini sorunsuzca yönetin. Kalite dökümanlarınızı oluşturun, değişiklikleri izleyin, sorumluları seçin, görevleri atayın, varlık envanterlerinizi oluşturun, potansiyel riskleri kontrol edin.",
  },
  {
    id: 14,
    imageurl: "https://brnsoft.net/pictures/bilimp/14talep.png",
    name: "Kurumunuzun taleplerini alın, yönetin, düzenleyin",
    desc: "Ekip üyelerinizin izinden harcıraha, araçtan kırtasiyeye kadar belirlediğiniz tüm taleplerini kolayca yönetin.",
  },
  {
    id: 15,
    imageurl: "https://brnsoft.net/pictures/bilimp/15egitim.png",
    name: "Eğitim Yönetimi",
    desc: "Bilimp “Eğitim Aracı” ile çalışanlarınızın şirket ve kurum içi eğitimlerini etkin, hızlı ve sonuç odaklı hale getirin. “Zaman” kazanırken “maliyetlerinizi” düşürün, kurumsal verimliliğinizi üst seviyeye taşıyın.",
  },
  {
    id: 16,
    imageurl: "https://brnsoft.net/pictures/bilimp/16idariisler.png",
    name: "İdari işlerinizle ilgili tüm bilgileri kayıt altına alın",
    desc: "Abonelikler, Teminat Mektupları, Krediler, Sigorta Poliçeleri, Resmi Belgeler, Gayrimenkuller, Hukuk Dosyaları, Düzenli Ödemeler, hepsi kontrol altında.",
  },
  {
    id: 17,
    imageurl: "https://brnsoft.net/pictures/bilimp/17bilgiislem.png",
    name: "Bilgi İşlem aracı ile IT süreçlerinizi takip edip, kolayca yönetin",
    desc: "Şifre yönetimi, Lisans Yönetimi, Cihaz Yönetimi, Talep Yönetimi, Testler, Varlık ve Risk Yönetimi, İçerik Yayın Yönetimi",
  },
  {
    id: 18,
    imageurl: "https://brnsoft.net/pictures/bilimp/18bilgiguvenligi.png",
    name: "Cumhurbaşkanlığı Rehberi ile uyumlu",
    desc: "Kurumunuzdaki bilgi varlıklarının envanterini oluşturun ve varlıkların kritiklik derecelerini belirleyin. Mevcut durumu ve boşlukları analiz edin ve alınan önlemleri denetleyerek iyileştirin.",
  },
  {
    id: 19,
    imageurl: "https://brnsoft.net/pictures/bilimp/19taseron.png",
    name: "Uçtan Uca Taşeron Yönetimi",
    desc: "Taşeron Yönetimi sürecindeki karmaşanın firmalara verim, zaman ve maliyet kaybı yüksek. Bilimp Taşeron Yönetimi ile artık bu süreç çok kolay!",
  },
  {
    id: 20,
    imageurl: "https://brnsoft.net/pictures/bilimp/20ziyaretci.png",
    name: "KVKK uyumlu",
    desc: "Ziyaretçi kaydı - Randevulu/Randevusuz,Ziyaret edilecek kişi seçimi ve onay,Yabancı misafirler için çoklu dil,Ziyaretçi kartı basımı,Temassız Geçiş,Ziyaretçilere adres ve harita gönderimi,Süreli ziyaretçi tanımlayabilme ve dahası",
  },
  {
    id: 21,
    imageurl: "https://brnsoft.net/pictures/bilimp/21bitime.png",
    name: "IT varlıkları ve İnsan Kaynağını Yönetmenin Kolay Yolunu Keşfedin",
    desc: "Yazılım ve donanım envanteri çıkartma,Gerçek yazılım kullanımı algılama, Otomatik bilgisayar aktivite bilgisi oluşturma, Yazılım kullanım analizi",
  },
  {
    id: 22,
    imageurl: "https://brnsoft.net/pictures/bilimp/22envanter.png",
    name: "Envanter Yönetimi",
    desc: "Envanter ve varlık yönetimi süreçleri, işletmenin sürekliliği ve verimliliği için doğru envanter ve varlık yönetimi, işletmelerin varlıklarını etkin bir şekilde kullanmalarını, kaynaklarını boşa harcamamalarını ve işletmenin sürdürülebilirliğini sağlar.",
  },
  {
    id: 23,
    imageurl: "https://brnsoft.net/pictures/bilimp/23ebys.png",
    name: "Elektronik Belge Yönetim Sistemi",
    desc: "Bilimp EBYS ile yazışmalarınızda standartlaşma sağlayın",
  },
  {
    id: 24,
    imageurl: "https://brnsoft.net/pictures/bilimp/24puantaj.png",
    name: "Personellerinizin konum bazlı giriş çıkış bilgilerini kolayca alın, saklayın",
    desc: "Herhangi bir yazılım ve donanıma ihtiyaç duymadan, tamamen web üzerinden puantaj takibi yapın, tek tıkla raporlayın.",
  },
  {
    id: 25,
    imageurl: "https://brnsoft.net/pictures/bilimp/25onay.png",
    name: "Onay Optimizasyonu",
    desc: "Kişilerinize Onay Formlarını Hızlıca Gönderin,Bilgi Formlarını Dijital Doldurtun,Taslak Formlar Oluşturun,Onay Kayıtlarını Görüntüleyin,Dokümanlarınızı Düzenli Şekilde Arşivleyin,Kişi Gruplarına Form Gönderin",
  },
];

export const mihsapProductData = [
  {
    id: 1,
    imageurl: "https://brnsoft.net/pictures/mihsap/fatura.png",
    name: "E-faturalar Tek tıkla aktarılsın, arşivlensin",
    desc: "Muhasebe Evrak Kayıt Sürecini Dijitalleştirip, Otomatik Hale Getirin",
  },
  {
    id: 2,
    imageurl: "https://brnsoft.net/pictures/mihsap/stoklufatura.png",
    name: "Stoklu E-faturalar Tek tıkla aktarılsın, arşivlensin",
    desc: "Artan personel maliyetleri, yakıt fiyatları, kırtasiye masraflarına rağmen tasarruf edin",
  },
  {
    id: 3,
    imageurl: "https://brnsoft.net/pictures/mihsap/banka.png",
    name: "Tek tuşla banka aktarımları",
    desc: "Mihsap ile 7 farklı dosya formatı destekleyen altyapısıyla Banka evraklarınızı aktarabilirsiniz.",
  },

  {
    id: 4,
    imageurl: "https://brnsoft.net/pictures/mihsap/mobilweb1.png",
    name: "Muhasebenin geleceği",
    desc: "Hem müşavirlerin hem de mükelleflerin ulaşabileceği şekilde Mobil ve Web arayüzle işinizi hızlandırın.",
  },
];

export const sustableProductData = [
  {
    id: 1,
    imageurl: "https://brnsoft.net/pictures/sustable/esgmetrik1.png",
    name: "Esg Metrik Yöntemi",
    desc: "Çevresel, Sosyal ve yönetişim endekslerinde GRI standartlarında veri toplama ve konsolidasyon gerçekleştirin.",
  },
  {
    id: 2,
    imageurl: "https://brnsoft.net/pictures/sustable/iso14064.png",
    name: "ISO 14064-1 2018 Standardı",
    desc: "Organizasyon düzeyindeki sera gazı envanterlerinin tasarlanması, geliştirilmesi, yönetilmesi ve raporlanması için ilkeleri ve gereksinimleri detaylandırır.",
  },
  {
    id: 3,
    imageurl: "https://brnsoft.net/pictures/sustable/ghg.png",
    name: "GHG Protokolü",
    desc: "Kurumsal Muhasebe ve Raporlama Standardı, kurumsal düzeyde GHG emisyon envanteri hazırlayan şirketler ve diğer kuruluşlar için gereksinimler ve rehberlik sağlamaktadır.",
  },
  {
    id: 4,
    imageurl: "https://brnsoft.net/pictures/sustable/skdm1.png",
    name: "SKDM",
    desc: "Avrupa Birliği’ne ithal edilen ürünlerin karbon ayakizinin raporlanmasını içermektedir.",
  },
];
export const bilnexProductData = [
  {
    id: 1,
    imageurl: "https://brnsoft.net/pictures/bilnex/jrdynamic.png",
    name: "Bilnex Jr Dynamic",
    desc: "Stok, Cari Hesap, Kasa, Fatura işlemleri",
  },
  {
    id: 2,
    imageurl: "https://brnsoft.net/pictures/bilnex/lite.png",
    name: "Bilnex Lite",
    desc: "Stok(Detaylı), Fatura(Detaylı), Kasa, Çek Senet, Cari Hesap, Banka, Personel, Masraf",
  },
  {
    id: 3,
    imageurl: "https://brnsoft.net/pictures/bilnex/dynamic.png",
    name: "Bilnex Dynamic",
    desc: "Stok(Detaylı), Cari Hesap, Fatura(Detaylı), Kasa, Çek senet, Banka, Personel, Masraf, Taksitli Satış, Proje",
  },
  {
    id: 4,
    imageurl: "https://brnsoft.net/pictures/bilnex/entegre.png",
    name: "Bilnex Entegre",
    desc: "Stok(Detaylı), Fatura(Detaylı), Cari Hesap, Kasa, Çek Senet, Banka, Personel, Masraf, Taksitli Satış, Proje, Genel Muhasebe",
  },
  {
    id: 5,
    imageurl: "https://brnsoft.net/pictures/bilnex/pluslite.png",
    name: "Bilnex Plus Lite",
    desc: "Stok(Detaylı), Fatura(Detaylı), Cari Hesap, Kasa, Çek Senet, Banka, Personel, Masraf, Taksitli Satış, Proje, Üretim(Detaylı)",
  },
  {
    id: 6,
    imageurl: "https://brnsoft.net/pictures/bilnex/plus.png",
    name: "Bilnex Plus",
    desc: "Stok(Detaylı), Fatura(Detaylı), Cari Hesap, Kasa, Çek Senet, Banka, Personel, Masraf, Taksitli Satış, Proje, Üretim(Detaylı),Genel Muhasebe",
  },
  {
    id: 7,
    imageurl: "https://brnsoft.net/pictures/bilnex/jrpos.png",
    name: "Bilnex Jr Pos",
    desc: "Hızlı Satış, Stok, Cari Hesap, Fatura, Kasa, Masraf",
  },
  {
    id: 8,
    imageurl: "https://brnsoft.net/pictures/bilnex/litepos.png",
    name: "Bilnex Lite Pos",
    desc: "Hızlı Satış, Stok (Detaylı), Cari Hesap, Fatura, Kasa, Masraf",
  },
  {
    id: 9,
    imageurl: "https://brnsoft.net/pictures/bilnex/pos.png",
    name: "Bilnex Pos",
    desc: "Hızlı Satış, Stok (Detaylı), Cari Hesap, Fatura, Kasa, Masraf, Banka",
  },
  {
    id: 10,
    imageurl: "https://brnsoft.net/pictures/bilnex/tpos.png",
    name: "Ticari ve Perakende Satış Yöntemi",
    desc: "Hızlı Satış, Stok (Detaylı), Cari Hesap, Fatura, Kasa, Çek Senet, Masraf, Banka, Personel, Taksitli Satış, Proje",
  },
  {
    id: 11,
    imageurl: "https://brnsoft.net/pictures/bilnex/power.png",
    name: "Kurumsal Perakende Satış Yöntemi",
    desc: "Hızlı Satış, Stok (Detaylı), Cari Hesap, Fatura, Kasa, Çek Senet, Masraf, Banka, Personel, Taksitli Satış, Proje, Genel Muhasebe",
  },
  {
    id: 12,
    imageurl: "https://brnsoft.net/pictures/bilnex/ekasa.png",
    name: "E-Kasa",
    desc: "Hızlı Satış Noktaları İçin Yazar Kasaya İhtiyaç Duymadan Uygun Maliyetlerle E-Arşiv Fatura Düzenleyebilme",
  },
  {
    id: 13,
    imageurl: "https://brnsoft.net/pictures/bilnex/okcmodul.png",
    name: "Yeni Nesil ÖKC Haberleşme Modülü",
    desc: "Yazar Kasa İle Bilnex Hızlı Satış Programı Arasındaki Entegrasyonu Sağlar",
  },
  {
    id: 14,
    imageurl: "https://brnsoft.net/pictures/bilnex/veritransferi.png",
    name: "Şubelerinizde İnternet Kesintilerinden Etkilenmeyin",
    desc: "Windows Servisi Olarak Çalışabilme, Şubeli Yapıda Çalışabilme, Satışları Merkezde Toplayabilme, Şube Bazlı Rapor, Merkezden Satış Kontrol, Offline Çalışabilme",
  },
  {
    id: 15,
    imageurl: "https://brnsoft.net/pictures/bilnex/fiyatgor.png",
    name: "Fiyat Gör Cihazlarıyla Uyumlu",
    desc: "Google Play, Microsoft Store",
  },
  {
    id: 16,
    imageurl: "https://brnsoft.net/pictures/bilnex/sahasatislite.png",
    name: "Mobilnex Saha Satış Lite",
    desc: "Stok, Cari, Sayım, Sipariş, İrsaliye, Fatura, Satış Fişi, Tahsilat ve Ödeme, Kasa Masraf Fişi, Stok Fişi, Rapor",
  },
  {
    id: 17,
    imageurl: "https://brnsoft.net/pictures/bilnex/sahasatispro.png",
    name: "Mobilnex Saha Satış Pro",
    desc: "Stok, Cari, Sayım, Sipariş, İrsaliye, Fatura, Satış Fişi, Tahsilat ve Ödeme, Kasa Masraf Fişi, Stok Fişi, Rapor, Satış SÖzleşmeleri, Rota ve Ziyaret Takibi",
  },
  {
    id: 18,
    imageurl: "https://brnsoft.net/pictures/bilnex/sayimyonetimi.png",
    name: "Mobilnex Sayım Yönetimi",
    desc: "Stok Bağlantılı Sayım",
  },
  {
    id: 19,
    imageurl: "https://brnsoft.net/pictures/bilnex/mfiyatgor.png",
    name: "Mobilnex Fiyat Gör",
    desc: "Barkoddan Fiyat Sorgulama, Stok Miktarı Sorgulama, Ürün Adından Fiyat Sorgulama, Depo Bazında Stok Miktarı Sorgulama, Renk - Beden Detaylı Stok Miktarı Sorgulama",
  },
  {
    id: 20,
    imageurl: "https://brnsoft.net/pictures/bilnex/ceppatron.png",
    name: "Cep Patron",
    desc: "Günlük-Haftalık-Aylık Perakende Satış Raporları, Stok Raporları, Cari Raporları, Kasa Banka Raporları, Sipariş ve Tahsilat İşlemleri, Çek Senet Raporları, Fatura Raporları, İrsaliye Raporları, Sipariş Raporları, Teklif Raporları, Gider Raporları",
  },
  {
    id: 21,
    imageurl: "https://brnsoft.net/pictures/bilnex/erp.png",
    name: "ERP",
    desc: "Kurumsal Kaynak Planlama Yönetim Sistemi",
  },
  {
    id: 22,
    imageurl: "https://brnsoft.net/pictures/bilnex/otoservis.png",
    name: "Oto Servis Yönetimi",
    desc: "Araç Kartı, Araç Kabul Fişi(İş Emri), Araç Kabullerinin Listesi",
  },
  {
    id: 23,
    imageurl: "https://brnsoft.net/pictures/bilnex/otoservislite.png",
    name: "Oto Servis Yönetimi",
    desc: "Araç Kartı, İş Emri Formu, Randevu, Fatura, Kasa, Kayıt Kabul, Arıza Takibi, Araç Geçmişi, Stok, Cari Hesap, Tahmini Servise Gelmesi Gereken Araçlar Raporu, Servis Paket Tanımlayabilme",
  },
  {
    id: 24,
    imageurl: "https://brnsoft.net/pictures/bilnex/otoservispro.png",
    name: "Ön Muhasebe ve Oto Servis Yönetimi",
    desc: "Araç Kartı, İş Emri Formu, Randevu, Fatura, Kasa, Çek Senet, Banka, Masraf, Personel, Kayıt Kabul, Arıza Takibi, Araç Geçmişi, Stok, Cari Hesap, Tahmini Servise Gelmesi Gereken Araçlar Raporu, Servis Paket Tanımlayabilme",
  },
  {
    id: 25,
    imageurl: "https://brnsoft.net/pictures/bilnex/zirai.png",
    name: "Bitki Koruma Ürünleri Takip Sistemi (BKST)",
    desc: "E-Arşiv Fatura, E-İrsaliye, Stok, Anlık Bildirimler(Mal Kabul, Satış, İade, Ürün İmha, Depo Sevk, Mobil), S.K.T Yaklaşan Ürünler Raporu, Çok Şubeli Depolu Takip",
  },
  {
    id: 26,
    imageurl: "https://brnsoft.net/pictures/bilnex/restoran.png",
    name: "Restoran Yönetim Sistemi",
    desc: "Masa Takibi, Sipariş Takibi, Self Servis, Garson Takibi, Caller Id, Paket Servis ve Kurye Takibi, Ön Ödemeli Satış, Cari, Personel, Fatura, Kasa, Banka, Masraf",
  },
  {
    id: 27,
    imageurl: "https://brnsoft.net/pictures/bilnex/restoranpro.png",
    name: "Restoran Yönetim Sistemi Pro",
    desc: "Üretim Reçetesi, Toplu Üretim, Masa Takibi, Sipariş Takibi, Self Servis, Garson Takibi, Caller Id, Paket Servis ve Kurye Takibi, Ön Ödemeli Satış, Cari, Personel, Fatura, Kasa, Banka, Masraf, Çek Senet",
  },
  {
    id: 28,
    imageurl: "https://brnsoft.net/pictures/bilnex/qrsistem.png",
    name: "Qr Sistemi",
    desc: "QR Menü, QR Siparişi, QR Ödeme, QR Menu Yabancı Dil Desteği, Şubeli QR Menü, Mobil Garson",
  },
];
