import { useEffect } from "react";

export default function Scripts() {
  useEffect(() => {
    const handleAccordionClick = (event) => {
      const item = event.target.closest(".accordion-content");
      if (item) {
        item.classList.toggle("open");
        const description = item.querySelector(".description");
        if (item.classList.contains("open")) {
          description.style.height = `${description.scrollHeight}px`;
        } else {
          description.style.height = "0px";
        }
        removeOpen(item);
      }
    };

    function removeOpen(currentItem) {
      const accordionContentList = Array.from(
        document.querySelectorAll(".accordion-content")
      );
      accordionContentList.forEach((item) => {
        if (item !== currentItem) {
          item.classList.remove("open");
          const description = item.querySelector(".description");
          description.style.height = "0px";
        }
      });
    }

    const accordionContentList = Array.from(
      document.querySelectorAll(".accordion-content")
    );
    accordionContentList.forEach((item) => {
      const header = item.querySelector("header");
      header.addEventListener("click", handleAccordionClick);
    });

    return () => {
      accordionContentList.forEach((item) => {
        const header = item.querySelector("header");
        header.removeEventListener("click", handleAccordionClick);
      });
    };
  }, []);

  return null;
}

// import React, { useEffect, useRef } from "react";

// export default function Scripts() {
//   const accordionContentListRef = useRef([]);

//   useEffect(() => {
//     const handleAccordionClick = (event, index) => {
//       const item = event.target.closest(".accordion-content");
//       if (item) {
//         item.classList.toggle("open");
//         const description = item.querySelector(".description");
//         if (item.classList.contains("open")) {
//           description.style.height = `${description.scrollHeight}px`;
//         } else {
//           description.style.height = "0px";
//         }
//         removeOpen(index);
//       }
//     };

//     function removeOpen(index1) {
//       accordionContentListRef.current.forEach((item, index2) => {
//         if (index1 !== index2) {
//           item.classList.remove("open");
//           const description = item.querySelector(".description");
//           description.style.height = "0px";
//         }
//       });
//     }

//     const accordionContentList = Array.from(
//       document.querySelectorAll(".accordion-content")
//     );
//     accordionContentListRef.current = accordionContentList;

//     accordionContentList.forEach((item, index) => {
//       const header = item.querySelector("header");
//       header.addEventListener("click", (event) =>
//         handleAccordionClick(event, index)
//       );
//     });

//     return () => {
//       accordionContentList.forEach((item, index) => {
//         const header = item.querySelector("header");
//         header.removeEventListener("click", (event) =>
//           handleAccordionClick(event, index)
//         );
//       });
//     };
//   }, []);

//   return null;
// }
