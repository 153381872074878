import React from "react";
import "./bilimp.css";
import logohero from "./assets/bilimphero.png";
import logo2 from "./assets/bilimp2.png";
import logo3 from "./assets/bilimp3.png";
import Hero from "./components/Hero";
import Slider from "./components/Slider";
function Bilimp() {
  return (
    <div className="Bilimp">
      <Hero imageSrc={logohero} />
      <Slider imageSrc={logo2} title={"Neden Bilimp?"} subtitle={""} />
      <Slider
        imageSrc={logo3}
        title={"İnsan kaynaklarınızı verimli yönetin."}
        subtitle={""}
        flipped={true}
      />
    </div>
  );
}

export default Bilimp;
