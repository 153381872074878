import "./App.css";
import "react-multi-carousel/lib/styles.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Logo from "./components/Firma/Logo/Logo";
import Elogo from "./components/Firma/Elogo/Elogo";
// import Unideva from "./components/Firma/Unideva/Unideva";
import Narbulut from "./components/Firma/Narbulut/Narbulut";
import Home from "./components/Home/Home";
import Layout from "./components/Layout/Layout";
import Bilimp from "./components/Firma/Bilimp/Bilimp";
import Mihsap from "./components/Firma/Mihsap/Mihsap";
import Sustable from "./components/Firma/Sustable/Sustable";
import Bilnex from "./components/Firma/Bilnex/Bilnex";

function App1() {
  return (
    // alttaki style sonradan
    <div>
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/logo" element={<Logo />} />
            <Route path="/elogo" element={<Elogo />} />
            <Route path="/bilnex" element={<Bilnex />} />
            {/* <Route path="/unideva" element={<Unideva />} /> */}
            <Route path="/narbulut" element={<Narbulut />} />
            <Route path="/bilimp" element={<Bilimp />} />
            <Route path="/mihsap" element={<Mihsap />} />
            <Route path="/sustable" element={<Sustable />} />

            {/* yanlış adres girince, Home page e yönlendirecek altta */}
            <Route path="*" element={<Home />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App1;
