import React from "react";
import "./elogo.css";
import elogo2 from "./assets/elogo2.png";
import elogo3 from "./assets/elogo3.png";
import Hero from "./components/Hero";
import Slider from "./components/Slider";
import elogohero from "./assets/elogohero.png";
function Elogo() {
  return (
    <div className="Elogo">
      <Hero imageSrc={elogohero} />
      <Slider imageSrc={elogo2} title={"Neden eLogo?"} subtitle={""} />
      <Slider
        imageSrc={elogo3}
        title={"Sağlam Altyapı, Sorunsuz Kullanım"}
        subtitle={""}
        flipped={true}
      />
    </div>
  );
}

export default Elogo;
