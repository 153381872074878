import React from "react";
import "./footer.css";
// import { AiFillFacebook } from "react-icons/ai";

export default function Footer() {
  return (
    <div>
      <footer className="footer">
        <div className="waves">
          <div className="wave" id="wave1"></div>
          <div className="wave" id="wave2"></div>
          <div className="wave" id="wave3"></div>
          <div className="wave" id="wave4"></div>
        </div>
        {/* <ul className="social-icon">
          <li className="social-icon__item">
            <a className="social-icon__link" href="https://www.brnsoft.net">
              <AiFillFacebook name="logo-facebook"></AiFillFacebook>
            </a>
          </li>
          <li className="social-icon__item">
            <a className="social-icon__link" href="https://www.brnsoft.net">
              <AiFillFacebook name="logo-twitter"></AiFillFacebook>
            </a>
          </li>
          <li className="social-icon__item">
            <a className="social-icon__link" href="https://www.brnsoft.net">
              <AiFillFacebook name="logo-linkedin"></AiFillFacebook>
            </a>
          </li>
          <li className="social-icon__item">
            <a className="social-icon__link" href="https://www.brnsoft.net">
              <AiFillFacebook name="logo-instagram"></AiFillFacebook>
            </a>
          </li>
        </ul> */}
        {/* <ul className="menu">
          <li className="menu__item">
            <a className="menu__link" href="https://www.brnsoft.net">
              Home
            </a>
          </li>
          <li className="menu__item">
            <a className="menu__link" href="https://www.brnsoft.net">
              Customers
            </a>
          </li>
          <li className="menu__item">
            <a className="menu__link" href="https://www.brnsoft.net">
              Offers
            </a>
          </li>
          <li className="menu__item">
            <a className="menu__link" href="https://www.brnsoft.net">
              Contact
            </a>
          </li>
          <li className="menu__item">
            <a className="menu__link" href="https://www.brnsoft.net">
              Our app
            </a>
          </li>
        </ul> */}
        <p>&copy;2023 brnsoft | All rights reserved</p>
      </footer>
      {/* Resources */}
    </div>
  );
}
