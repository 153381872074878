import React from "react";
import "./narbulut.css";
import narbuluthero1 from "./assets/narbuluthero1.png";
import narbulut2 from "./assets/narbulut2.png";
import narbulut3 from "./assets/narbulut3.png";
import Hero from "./components/Hero";
import Slider from "./components/Slider";
function Narbulut() {
  return (
    <div className="Narbulut">
      <Hero imageSrc={narbuluthero1} />
      <Slider imageSrc={narbulut2} title={"Neden Narbulut?"} subtitle={""} />
      <Slider
        imageSrc={narbulut3}
        title={"Güvende olmak herkesin hakkı."}
        subtitle={""}
        flipped={true}
      />
    </div>
  );
}

export default Narbulut;
